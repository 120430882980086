import React from 'react'
import { Helmet } from 'react-helmet'

/* COMPONENTS */

import Layout from '../../components/Layout/index'
import '../diaexpress/diaexpress.scss'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-md-block d-none">
      <div className="backgroud-banner-diaxpress w-100">
        <div className="container d-flex justify-content-start">
          <div className="banner-info">
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-md-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center my-4">
        <div className="banner-info  d-flex flex-column align-items-center m-0 p-0">
        </div>
      </div>
    </section>
  </>
)

const PoliticaPrivacidade = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/clubdia/termos/', name: 'Termos' },
      { path: '/clubdia/politica-de-privacidade/', name: 'Política de Privacidade do ClubDia' }
    ]
  }
  const conteudoTabela = [
    {
      direito: 'Confirmação da existência de tratamento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Acesso',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Correção',
      prazo: 'Em até 15 dias úteis a partir do recebimento da requisição'
    },
    {
      direito: 'Anonimização, bloqueio ou eliminação',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Portabilidade',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Revogação ou não fornecimento do consentimento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Revisão de decisões automatizadas',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Eliminação dos Dados tratados com consentimento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Oposição',
      prazo: 'Em até 15 dias úteis a partir do recebimento da requisição'
    }
  ]
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Política de Privacidade do ClubDIA | DIA Supermercados</title>
        <meta name="description" content="Política de Privacidade do ClubDIA. Última atualização 02/10/2023." />
        <meta name="keywords" content="política de privacidade, clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner />
      <section className='container'>
        <div className="row">
          <div className="col-12">
            <p className='pt-lg-3 p-0 text-muted'><span className='font-size-12'>ÚLTIMA ATUALIZAÇÃO: 02/10/2023</span></p>
            <h1 className='text-red pt-2 pb-1 '>
              POLÍTICA DE PRIVACIDADE CLUB DIA
            </h1>
            <p className='font-size-1 pb-2'>
              A DIA BRASIL SOCIEDADE LTDA., inscrita no CNPJ nº 03.476.811/0001-51, com sede à Avenida Ibirapuera, 2332, Indianópolis, 
              na cidade de São Paulo, Estado de São Paulo, CEP: 04028-002 (“<b>DIA</b>” ou “<b>nós/nossos</b>”) valoriza a privacidade dos seus clientes, 
              parceiros, fornecedores e demais usuários (“<b>usuário</b>” ou “<b>você</b>”).
            </p>
            <p className='font-size-1 pb-2'>
              Por isso, o DIA preparou a presente Política de Privacidade (“<b>Política</b>” ou “<b>Política de Privacidade</b>”), que se destina a 
              informá-lo sobre o modo como nós utilizamos as informações coletadas quando você se torna um membro do Club Dia e/ou quando 
              visita e interage no aplicativo e site do Club Dia (“<b>Aplicativo</b>” ou “<b>Plataforma</b>”).
            </p>
            <p className='font-size-1 pb-2'>É muito importante que você leia este documento com atenção e entre em contato conosco, conforme aqui ao final indicado, caso tenha qualquer dúvida.</p>
            <p className='font-size-1 pb-4 font-weight-bold'>
              AO UTILIZAR A PLATAFORMA OU FORNECER SEUS DADOS PESSOAIS, VOCÊ DECLARA CIÊNCIA QUANTO ÀS PRÁTICAS DESCRITAS NESTA POLÍTICA E CONCORDA COM A UTILIZAÇÃO 
              DOS SEUS DADOS PESSOAIS. CASO VOCÊ NÃO ESTEJA DE ACORDO COM ESTA POLÍTICA, SOLICITAMOS QUE NÃO SE INSCREVA NO CLUB DIA, ACESSE, NAVEGUE OU FAÇA USO DA 
              PLATAFORMA OU FORNEÇA DADOS PARA USO DA PLATAFORMA.
            </p>
          </div>
          <div className="col-12">
            <ol className="title p-0">
              <li className="numberTitle"><span className='font-size-18'>DEFINIÇÕES</span>
                <ol>
                  <li className='font-size-1'>Para os fins desta Política de Privacidade:
                    <ul className='romanList text-dark'>
                      <li className='font-size-1'>
                        “<b>Dados Pessoais</b>” significa qualquer informação que, direta ou indiretamente, identifique ou possa 
                        identificar uma pessoa natural, como por exemplo, nome, CPF, data de nascimento, endereço IP, dentre outros. 
                        Engloba o conceito de Dados Pessoais Sensíveis;
                      </li>
                      <li className='font-size-1'>
                        “<b>Dados Pessoais Sensíveis</b>” significa qualquer informação que revele, em relação a uma pessoa natural, 
                        origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter 
                        religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico;
                      </li>
                      <li className='font-size-1'>
                        “<b>Encarregado</b>” significa a pessoa indicada pelo DIA para atuar como canal de comunicação entre o DIA, os 
                        titulares dos dados, incluindo você e a Autoridade Nacional de Proteção de Dados Pessoais (“ANPD”);
                      </li>
                      <li className='font-size-1'>
                        “<b>LGPD</b>” significa a <a href='http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm' className='text-red text-underline' target="_blank" rel="noreferrer">Lei nº 13.709/2018</a>, a Lei Geral de Proteção de Dados Pessoais.
                      </li>
                    </ul>
                  </li>
                  <li className='font-size-1'>Outros termos definidos e utilizados nessa Política, que não alterados, terão o mesmo significado a eles atribuídos nos Termos de Uso e/ou nas leis aplicáveis.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Quais informações o DIA coleta na Plataforma?</span>
                <ol>
                  <li className='font-size-1'>O DIA poderá coletar os seguintes Dados Pessoais que serão fornecidos (i) diretamente por você; ou (ii) coletados automaticamente sobre você, conforme a seguir:
                    <ul className='romanList text-dark'>
                      <li className='font-size-1'><b>Informações fornecidas diretamente por você:</b></li>
                      <p className='font-size-1 padding-list'>• <u className='ml-2'>Informações de contato.</u> No <a href='https://www.sequoialog.com.br/contato/' className='text-red text-underline' target="_blank" rel="noreferrer">contato conosco</a>, o usuário poderá inserir ativamente na Plataforma dados pessoais, tais como, nome, sobrenome, endereço de e-mail, telefone, e quaisquer outros no conteúdo de sua mensagem eventualmente enviada a nós.</p>
                      <p className='font-size-1 padding-list'>• <u className='ml-2'>Informações de cadastro.</u> Caso você queira fazer uso da nossa Plataforma e fazer parte do Club Dia, você precisará se cadastrar em nossa Plataforma. Nesse caso, poderemos coletar as seguintes informações sobre você: nome, sobrenome, CPF, endereço de e-mail, data de nascimento, telefone e CEP.</p>
                      <p className='font-size-1 padding-list'>• <u className='ml-2'>Informações de compra:</u> Todas as compras que você fizer em nossas lojas, usando o Club Dia, também ficarão atreladas ao seu perfil no Club Dia. Por exemplo, é possível sabermos quais produtos você adquiriu em nossos estabelecimentos e qual foi a data de sua compra.</p>
                      <li className='font-size-1'><b>Informações coletadas automaticamente sobre você:</b>
                        <p>Na navegação pelo Aplicativo ou a partir do login no Club Dia, sem qualquer interação direta conosco, 
                          por meio de uso de tecnologias diversas, o DIA poderá coletar informações tais como informações sobre o 
                          navegador e o dispositivo que você está usando, seu endereço IP, sua localização, o endereço do site a partir 
                          do qual você chegou. Algumas dessas informações são coletadas usando ferramentas tecnológicas que incluem 
                          cookies. Para saber mais, por favor acesse nossa seção de cookies.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className='font-size-1'>Você reconhece ser o único responsável pela veracidade, exatidão e autenticidade dos dados fornecidos diretamente por você, responsabilizando-se exclusivamente nesse sentido.</li>
                  <li className='font-size-1'>Nós não coletamos Dados Pessoais Sensíveis.</li>
                  <li className='font-size-1'>Não coletamos ou solicitamos intencionalmente Dados Pessoais de indivíduos menores de 18 (dezoito) anos de idade. Se você não tiver 18 (dezoito) anos de idade, não utilize o Aplicativo e/ou forneça quaisquer dados. Acesse a seção “Privacidade de Crianças” para mais informação.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Cookies</span>
                <ol>
                  <li className='font-size-1'>Quando você visita o Aplicativo, ele pode armazenar ou recuperar informações em seu navegador, seja na forma de cookies e de outras tecnologias semelhantes. Essas informações podem ser sobre você, suas preferências (de idioma, por exemplo) ou seu dispositivo e são usadas principalmente para que o Aplicativo funcione como você espera. As informações geralmente não o identificam diretamente, mas podem oferecer uma experiência na internet mais personalizada.</li>
                  <li className='font-size-1'>De acordo com esta Política de Privacidade, nós podemos coletar seus Dados Pessoais por meio de uso de cookies. Os cookies permitem a coleta de informações tais como o tipo de navegador, o tempo dispendido em nosso Aplicativo, as páginas visitadas, e outros dados de tráfego anônimos. Também coletamos informações estatísticas sobre o uso do Aplicativo para aprimoramento contínuo do nosso design e funcionalidades. Veja, a seguir, os tipos de cookies que utilizamos no Aplicativo:
                    <ul className='bulletsList text-dark'>
                      <li><b>Cookies de Análise:</b> Esses cookies são usados para coletar informações agregadas sobre o número de visitantes e como eles usam o site. Por exemplo, coletamos informações agregadas sobre o número de usuários e sessões; Duração da sessão; Sistemas operacionais; Modelos de dispositivos; região geografia; atualizações do aplicativo; e outras</li>
                    </ul>
                  </li>
                  <li className='font-size-1'>Caso não deseje que suas informações sejam coletadas por meio de cookies, você pode configurar os cookies no menu "opções" ou "preferências" do seu browser. Nos links abaixo você encontra mais detalhes sobre como ajustar as preferências de cookies dos navegadores de internet mais populares.
                    <ul className='bulletsList text-dark'>
                      <li>
                        <a href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&&oco=1&&hl=pt-BR' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Google Chrome
                        </a>
                      </li>
                      <li>
                        <a href='https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Mozilla Firefox
                        </a>
                      </li>
                      <li>
                        <a href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Safari
                        </a>
                      </li>
                      <li>
                        <a href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Internet Explorer
                        </a>
                      </li>
                      <li>
                        <a href='https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Microsoft Edge
                        </a>
                      </li>
                      <li>
                        <a href='https://help.opera.com/en/latest/web-preferences/' className='text-red text-underline' target="_blank" rel="noreferrer">
                          Opera
                        </a>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="numberTitle" id='teste-lista-ul'><span className='font-size-18'>Como o DIA utiliza os seus Dados Pessoais?</span>
                <ol>
                  <li className='font-size-1'>
                    Coletamos e tratamos Dados Pessoais, especialmente, para que possamos cumprir com nossas obrigações legais ou regulatórias; o contrato estabelecido com você; de acordo com o seu consentimento; e, ainda, de acordo com nosso legítimo interesse, no gerenciamento de nossas atividades. Exemplos de como usamos os Dados Pessoais incluem:
                    <ul className='bulletsList text-dark'>
                      <li className='font-size-1'>manutenção de logs de acesso, para permitir que o DIA cumpra com o Marco Civil da Internet;</li>
                      <li className='font-size-1'>para conduzir estudos analíticos acerca do uso do Aplicativo e interações feita pelos usuários;</li>
                      <li className='font-size-1'>para proporcionar um atendimento ao usuário ou suporte técnico eficaz;</li>
                      <li className='font-size-1'>para notificá-lo sobre alterações em nosso Aplicativo, políticas e outros;</li>
                      <li className='font-size-1'>para  o envio de ofertas de produtos em geral ou ofertas personalizadas, com base em compras realizadas por você;</li>
                      <li className='font-size-1'>para o envio de pesquisas de satisfação e de opinião, podendo ser sobre sua experiência de compra ou sobre seu ponto de vista sobre determinado assunto;</li>
                      <li className='font-size-1'>para envio de newsletters com novidades, ofertas e promoções do DIA por notificação push via App, e-mail, SMS, WhatsApp e Correio;</li>
                      <li className='font-size-1'>melhorar sempre as experiências e relacionamentos com nossa marca e produtos;</li>
                      <li className='font-size-1'>para habilitar a sua participação em campanhas e sorteios vigentes;</li>
                    </ul>
                  </li>
                  <li className='font-size-1'>O DIA tratará os seus Dados Pessoais somente para fins comunicados a você, a menos que julgue precisar deles para outros propósitos compatíveis com os originais, e que há uma base legal para o tratamento posterior. Ou seja, os Dados Pessoais poderão ser utilizados para finalidades secundárias não conflitantes e/ou excessivas em relação às finalidades aqui listadas.</li>
                </ol>
              </li>
              {/* <div className='w-50'></div> */}
              <li className="numberTitle"><span className='font-size-18'>Como e com quem o DIA compartilha os seus Dados Pessoais?</span>
                <ol className=''>
                  <li className='font-size-1'>
                    Não divulgamos os seus Dados Pessoais a terceiros de maneira que seria considerada uma venda ou comercialização. O DIA somente compartilhará seus Dados Pessoais com aqueles que tenham necessidade legítima para acessá-los. Sempre que o DIA permite que um terceiro acesse Dados Pessoais, o DIA se certifica de que os dados serão utilizados de maneira consistente com esta Política de Privacidade e quaisquer outras diretrizes aplicáveis.
                  </li>
                  <li>
                    Os seus Dados Pessoais podem ser compartilhados com terceiros, como com provedores de serviços, outros parceiros comerciais e/ou autoridades competentes para finalidades legítimas da seguinte forma:
                    <ul className='romanList text-dark'>
                      <li className='font-size-1'>para execução dos serviços necessários relacionados ao aplicativo e ao clube de vantagens;</li>
                      <li className='font-size-1'>para exercício legítimo de direito em processo judicial, administrativo ou arbitral, inclusive prevenindo lesão ou ameaça a direito;</li>
                      <li className='font-size-1'>para obedecer às obrigações legais, regulatórias ou contratuais a que estamos sujeitos, ou responder a um processo judicial ou administrativo, auditoria ou solicitações legais por autoridades públicas e policiais, quando entendermos que estamos legalmente obrigados a fazê-lo;</li>
                      <li className='font-size-1'>no contexto de operações de reorganização societária;</li>
                      <li className='font-size-1'>com empresas do nosso grupo econômico, afiliadas e coligadas;</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Por quanto tempo o DIA armazena os seus Dados Pessoais?</span>
                <ol>
                  <li className='font-size-1'>Os Dados Pessoais dos usuários que nos enviam informações por meio do nosso canal de atendimento são armazenados somente pelo tempo que for necessário para atendermos a sua solicitação/respondermos as suas dúvidas, salvo se houver qualquer outra razão que justifique a manutenção posterior como o cumprimento de quaisquer obrigações legais, regulatórias, contratuais, defesa de nossos direitos em eventual processo judicial ou administrativo entre outras.</li>
                  <li className='font-size-1'>Os Dados Pessoais dos usuários, assim como suas informações de compra e outras transações realizadas na Plataforma, são mantidas enquanto o usuário for ativo na Plataforma.</li>
                  <li className='font-size-1'>Quando no término do tratamento de seus dados pessoais, estes serão eliminados no âmbito e nos limites técnicos das atividades, autorizada a conservação nas situações previstas na legislação vigente.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Localização das informações e transferência internacional de Dados Pessoais</span>
                <ol>
                  <li className='font-size-1'>Via de regra, seus Dados Pessoais serão tratados e permanecerão armazenados no território brasileiro. Contudo, caso haja necessidade, por exemplo, em caso de utilização de um sistema de armazenamento em nuvem de dados, isso poderá ser entendido como transferência de seus Dados Pessoais para outros países.  Quando transferirmos os seus dados para outro país, garantiremos que essa transferência obedeça às normas aplicáveis e seja conduzida de modo a preservar os seus direitos.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Direitos do Usuário</span>
                <ol>
                  <li className='font-size-1'>Você, usuário pessoa física, pode, a qualquer momento, exercer os seguintes direitos:
                    <ul className='romanList text-dark'>
                      <li className='font-size-1'>confirmação de que seus Dados Pessoais estão sendo tratados;</li>
                      <li className='font-size-1'>acesso aos seus Dados Pessoais;</li>
                      <li className='font-size-1'>correções a dados incompletos, inexatos ou desatualizados;</li>
                      <li className='font-size-1'>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em lei;</li>
                      <li className='font-size-1'>portabilidade de Dados Pessoais a outro prestador de serviços, contanto que isso não afete nossos segredos industriais e comerciais;</li>
                      <li className='font-size-1'>eliminação de Dados Pessoais tratados com seu consentimento, na medida do permitido em lei;</li>
                      <li className='font-size-1'>solicitar informações sobre as entidades com as quais seus Dados Pessoais tenham sido compartilhados;</li>
                      <li className='font-size-1'>solicitar informações sobre a possibilidade de não fornecer o consentimento e sobre as consequências da negativa, sempre que for o caso;</li>
                      <li className='font-size-1'>revogação do consentimento, quando aplicável;</li>
                    </ul>
                  </li>
                  <li className='font-size-1'>Ainda, sempre que for o caso, você poderá solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses. Os usuários serão sempre comunicados caso algum tratamento de Dados Pessoais envolva processo automatizado. Além disso, os usuários podem se opor ao tratamento dos Dados Pessoais. No Aplicativo há tratamento de dados automatizados: criação de perfil de consumo para o direcionamento de ofertas customizada.</li>
                  <li className='font-size-1'>Lembramos que existem exceções a esses direitos, e, por exemplo, não será possível excluir seus dados se formos obrigados por lei a mantê-los ou se tivermos outras finalidades legitimas para tanto. Da mesma forma, o acesso aos seus dados pode ser recusado se a disponibilização das informações puder revelar Dados Pessoais de outras pessoas ou, ainda, informações que possam revelar nossos segredos de negócio.</li>
                  <li className='font-size-1'>Se você tiver criado uma conta na Plataforma, você poderá acessar e atualizar parte de ou todas as suas informações pessoais em suas configurações de conta ou página de perfil. Se não for possível, então você poderá entrar em contato diretamente conosco, por meio do <b>dpo.brasil@diagroup.com</b> ou através link do <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77' className='text-red text-underline' target="_blank" rel="noreferrer">Canal do DPO</a> e indicar qual direito você deseja exercer.</li>
                  <li className='font-size-1'>A partir de uma solicitação sua, entraremos em contato com você e podemos pedir informações adicionais para verificar sua identidade. Na maioria dos casos, atualizaremos as informações conforme solicitado. Entretanto, em alguns casos, podemos limitar ou recusar sua solicitação se permitido ou exigido pela lei ou se não pudermos verificar sua identidade.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Privacidade de crianças e adolescentes</span>
                <ol>
                  <li className='font-size-1'>A Plataforma não se destina a menores de 18 anos de idade, e o DIA não coleta informações de crianças (até 12 anos de idade incompletos) e adolescentes (entre 12 e 18 anos de idade incompletos) para vender ou promover nossos produtos ou serviços. Ninguém com idade inferior a 18 anos pode fornecer informações ao DIA por meio da Plataforma e, caso tenhamos conhecimento de que recebemos informações de uma criança ou adolescente, excluiremos essas informações.</li>
                  <li className='font-size-1'>Se você tomar conhecimento que uma criança ou um adolescente enviou informações de contato pessoais ao DIA, você deve entrar em contato conosco imediatamente para que possamos remover rapidamente essas informações de nossos sistemas ou tomar as providências devidas.  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Segurança</span>
                <ol>
                  <li className='font-size-1'>Tomamos precauções comercialmente razoáveis para proteger a confidencialidade, integridade e segurança de suas informações pessoais. Por isso, o DIA adota medidas técnicas, físicas e organizacionais adequadas para evitar a violação de Dados Pessoais. Por exemplo, o DIA emprega controles de acesso físico e digital, firewalls e antivírus em suas máquinas e realiza procedimentos regulares de monitoramento e varreduras em seus equipamentos. O pessoal do DIA ou terceiros pelo DIA contratados que tiverem acesso aos Dados Pessoais são obrigados a manter essas informações confidenciais e cumprir com políticas e procedimentos internos de segurança. Os terceiros com os quais compartilhamos os seus Dados Pessoais também são obrigados a manter medidas que garantam a segurança dos seus Dados Pessoais.</li>
                  <li className='font-size-1'>Quaisquer divulgações legalmente exigidas em caso de algum incidente de segurança serão noticiadas adequadamente a você via e-mail ou disponibilizada de forma ostensiva nos canais de comunicação do DIA, bem como às autoridades competentes, incluindo a Autoridade Nacional de Proteção de Dados, conforme for necessário.</li>
                  <li className='font-size-1'>Caso tenha motivos para acreditar que sua interação conosco tenha deixado de ser segura ou que seus dados de identificação foram acessados indevidamente, você deve nos comunicar imediatamente por meio do e-mail <a href='mailto:dpo.brasil@diagroup.com' className='text-red text-underline' target="_blank" rel="noreferrer">dpo.brasil@diagroup.com</a> ou do link <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77' className='text-red text-underline' target="_blank" rel="noreferrer">Canal do DPO</a>.</li>
                  <li className='font-size-1'>É importante que você também tenha um papel em manter seus dados pessoais seguros. Quando criar o login de acesso, por favor, assegure-se de escolher uma senha que seja forte para evitar que partes não autorizadas adivinhem. Recomendamos que você nunca revele ou compartilhe sua senha com outras pessoas. Você é o único responsável por manter esta senha confidencial.</li>
                  <li className='font-size-1'>Não solicitaremos ao usuário que informe sua senha fora do aplicativo, a senha deverá ser usada exclusivamente no momento do acesso ao aplicativo pelo usuário e, caso suspeite que sua senha tenha sido exposta a terceiros, recomendamos a imediata substituição da senha.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Links externos</span>
                <ol>
                  <li className='font-size-1'>A Plataforma pode conter links para outros websites na internet que não estão sob o controle ou são mantidos pelo DIA. Tais links não constituem uma aprovação pelo DIA desses websites, o conteúdo exibido ou as pessoas ou entidades associadas a eles. Ao visitar um website vinculado a partir do Aplicativo do DIA, você confirma que o DIA está fornecendo esses links para você apenas como uma conveniência e você concorda que o DIA não é responsável pelo conteúdo de tais websites. Sites externos são regulamentados por seus próprios termos e políticas. Consulte as referidas políticas antes de enviar quaisquer Dados Pessoais para esses sites.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Alterações a esta Política de Privacidade</span>
                <ol>
                  <li className='font-size-1'>Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento, publicando o texto atualizado no Aplicativo e a data de entrada em vigor atualizada na parte superior desta página. À medida que adicionamos novos recursos e funcionalidades a nossa Plataforma ou leis aplicáveis evoluem, podemos precisar atualizar ou revisar esta Política de Privacidade. Reservamo-nos o direito de fazer isso, a qualquer momento e sem aviso prévio, publicando uma versão revisada em nossa Plataforma. As alterações entrarão em vigor a partir da data em que publicarmos uma versão revisada em nosso website.</li>
                  <li className='font-size-1'>Se fizermos alterações a esta Política de Privacidade que alterem materialmente a maneira como tratamos seus dados pessoais, faremos um esforço razoável para notificá-lo imediatamente por meios razoáveis.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Contato</span>
                <ol>
                  <li className='font-size-1'>Caso pretenda exercer qualquer um dos direitos previstos nesta Política de Privacidade ou na LGPD ou resolver quaisquer dúvidas relacionadas ao tratamento de seus Dados Pessoais, favor contatar-nos, O DIA Brasil receberá, investigará e responderá, dentro de um prazo considerado razoável, qualquer solicitação ou reclamação sobre a forma como nós tratamos seus dados pessoais, incluindo reclamações sobre desrespeito aos seus direitos sob as leis de privacidade e proteção de dados pessoais vigentes.</li>
                  <li className='font-size-1'>Sempre que você tiver alguma dúvida sobre esta Política de Privacidade, mesmo após sua leitura, ou precisar interagir conosco sobre assuntos envolvendo os seus dados pessoais, poderá fazê-lo por meio dos nossos canais de atendimento disponíveis:
                    <ul className='bulletsList text-dark'>
                      <li>Encarregado pelo Tratamento de Dados Pessoais:
                        <ul className='squareList text-dark'>
                          <li className='font-size-1'>E-mail do encarregado (a) de dados: dpo.brasil@diagroup.com</li>
                          <li className='font-size-1'>Nome do Encarregados (as): Tassia Siqueira e Fernando Romero</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>Legislação de foro aplicável</span>
                <ol>
                  <li className='font-size-1'>A base legal apresentada na política de privacidade, será interpretada segundo a legislação brasileira pela lei 13.709/2019, na atualização da Lei 13.853/2019.</li>
                  <li className='font-size-1'>Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de São Paulo/SP, como o competente para dirimir quaisquer questões porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.</li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PoliticaPrivacidade
